import _ from "lodash";

export function isFunction(el) {
  return typeof el === "function";
}

var range = function (start, end) {
  var list = [];
  for (var i = start; i < end; i++) list.push(i);
  return list;
};
export { range };

export function cloneWithFunctions(from) {
  if (from == null || typeof from != "object" || from instanceof RegExp) {
    return from;
  }

  var to = null;
  if (from.constructor === Array) {
    to = to || from.slice(0);
    for (var i = 0; i < to.length; i++) {
      to[i] = cloneWithFunctions(to[i]);
    }
  } else {
    to = to || {};
    for (var name in from) {
      if (name != null) {
        to[name] =
          typeof to[name] == "undefined"
            ? cloneWithFunctions(from[name])
            : to[name];
      }
    }
  }

  return to;
}

var breakUpType = function (type) {
  return {
    val: Array.isArray(type) ? type[0] : type,
    name: Array.isArray(type) ? type[1] : type,
    extra: Array.isArray(type) ? type[2] || {} : {}
  };
};
export { breakUpType };

/*
Returns a list of data entries from a given data field with optional paremeters for filtering and sorting.
Passing opts.sectionFormat allows for filtering out entries with types which are not explicitly included in a section definition
Passing opts.sort = true will sort based on information in the content and format paramters, so opts.formats and opts.contextState are also required.
*/
var getField = function (cvDef, field, opts) {
  opts = opts || {};
  if (cvDef.lists[field] == null) cvDef.lists[field] = {}; // Likely new for this template.

  let ids = Object.keys(cvDef.lists[field]);
  if (opts.sectionFormat != null) {
    let types = (opts.sectionFormat?.items || []).find(
      (item) => item.type == "type"
    )?.types;

    // Filter data to types which exist
    ids = ids.filter(function (id) {
      var data = cvDef.lists[field][id];
      if (data == null) return false;
      if (types != null && data.type == null) {
        // Carried over from a non-typed format. Assign a default type (assuming first). When going back, types won't matter
        data.type = breakUpType(types[0]).val;
      }
      if (
        types != null &&
        !types.some(function (type) {
          return breakUpType(type).val == data.type;
        })
      )
        return false; // If type is not an option, dont include
      return true;
    });
  }

  let result = ids
    .map(function (id) {
      let item = cvDef.lists[field][id];
      if (item != null && !!opts.addId) {
        item = clone(item);
        item.id = id;
      }
      return item;
    })
    .filter((item) => item != null);

  return result;
};
export { getField };

// extends 'from' object with members from 'to'. If 'to' is null, a deep clone of 'from' is returned
export function clone(from, avoid, to) {
  avoid = avoid || {};
  if (isFunction(from)) return null;
  if (from == null || typeof from != "object") return from;

  if (from.constructor === Array) {
    to = to || from.slice(0);
    for (var i = 0; i < to.length; i++) {
      to[i] = clone(to[i], avoid);
    }
  } else {
    to = to || {};
    for (var name in from) {
      if (name != null && avoid[name] == null) {
        to[name] =
          typeof to[name] == "undefined" ? clone(from[name], avoid) : to[name];
      }
    }
  }

  return to;
}

var keys = Object.keys;
export { keys };

var numKeys = function (obj) {
  return Object.keys(obj).length;
};
export { numKeys };

var writeList = function (list, joinText) {
  if (joinText == null) joinText = "and";
  if (list.length > 2)
    return (
      list.slice(0, -1).join(", ") + ", " + joinText + " " + list.slice(-1)[0]
    );
  else if (list.length == 1) return list[0];
  else return list.join(" " + joinText + " ");
};
export { writeList };

var removeEmptyKeys = function (obj) {
  for (var key in obj) {
    if (obj[key] == null) delete obj[key];
    else if (typeof obj[key] == "object") removeEmptyKeys(obj[key]);
  }
  return obj;
};
export { removeEmptyKeys };

var alphabetizeObj = function (obj) {
  return _.fromPairs(
    _.sortBy(_.toPairs(obj), function (n) {
      return n[0];
    })
  );
};
export { alphabetizeObj };

var alphabetizeArray = function (obj) {
  var to = null;
  if (obj.constructor === Array) {
    to = to || obj.slice(0);
    for (var i = 0; i < to.length; i++) to[i] = alphabetizeArray(to[i]);
  } else if (obj.constructor === Object) {
    to = to || {};
    obj = alphabetizeObj(obj);
    for (var name in obj) {
      if (name != null) {
        to[name] =
          typeof to[name] == "undefined"
            ? alphabetizeArray(obj[name])
            : to[name];
      }
    }
  } else to = obj;
  return to;
};
export { alphabetizeArray };

var isDiff = function (a, b, opts) {
  if (typeof a == "boolean" && typeof b == "boolean") return a !== b;
  if (typeof a == "number" && typeof b == "number") return a !== b;
  if (typeof a == "bigint" && typeof b == "bigint") return a !== b;

  opts = opts || {};
  if (opts.ignore != null) {
    // Ignore certain parameters like 'updateTime'
    var newA = {};
    for (var key in a) if (opts.ignore[key] == null) newA[key] = a[key];
    var newB = {};
    for (var key in b) if (opts.ignore[key] == null) newB[key] = b[key];
    return JSON.stringify(newA) !== JSON.stringify(newB);
  }
  return (
    JSON.stringify(alphabetizeObj(a)) !== JSON.stringify(alphabetizeObj(b))
  );
};
export { isDiff };

var empty = function (obj) {
  if (obj == null || typeof obj !== "object") return true;
  return Object.keys(obj).every(function (key) {
    return obj[key] == null;
  });
};
export { empty };

export const MD5 = function (d) {
  var r = M(V(Y(X(d), 8 * d.length)));
  return r.toLowerCase();
};
function M(d) {
  for (var _, m = "0123456789ABCDEF", f = "", r = 0; r < d.length; r++)
    (_ = d.charCodeAt(r)), (f += m.charAt((_ >>> 4) & 15) + m.charAt(15 & _));
  return f;
}
function X(d) {
  for (var _ = Array(d.length >> 2), m = 0; m < _.length; m++) _[m] = 0;
  for (m = 0; m < 8 * d.length; m += 8)
    _[m >> 5] |= (255 & d.charCodeAt(m / 8)) << m % 32;
  return _;
}
function V(d) {
  for (var _ = "", m = 0; m < 32 * d.length; m += 8)
    _ += String.fromCharCode((d[m >> 5] >>> m % 32) & 255);
  return _;
}
function Y(d, _) {
  (d[_ >> 5] |= 128 << _ % 32), (d[14 + (((_ + 64) >>> 9) << 4)] = _);
  for (
    var m = 1732584193, f = -271733879, r = -1732584194, i = 271733878, n = 0;
    n < d.length;
    n += 16
  ) {
    var h = m,
      t = f,
      g = r,
      e = i;
    (f = md5_ii(
      (f = md5_ii(
        (f = md5_ii(
          (f = md5_ii(
            (f = md5_hh(
              (f = md5_hh(
                (f = md5_hh(
                  (f = md5_hh(
                    (f = md5_gg(
                      (f = md5_gg(
                        (f = md5_gg(
                          (f = md5_gg(
                            (f = md5_ff(
                              (f = md5_ff(
                                (f = md5_ff(
                                  (f = md5_ff(
                                    f,
                                    (r = md5_ff(
                                      r,
                                      (i = md5_ff(
                                        i,
                                        (m = md5_ff(
                                          m,
                                          f,
                                          r,
                                          i,
                                          d[n + 0],
                                          7,
                                          -680876936
                                        )),
                                        f,
                                        r,
                                        d[n + 1],
                                        12,
                                        -389564586
                                      )),
                                      m,
                                      f,
                                      d[n + 2],
                                      17,
                                      606105819
                                    )),
                                    i,
                                    m,
                                    d[n + 3],
                                    22,
                                    -1044525330
                                  )),
                                  (r = md5_ff(
                                    r,
                                    (i = md5_ff(
                                      i,
                                      (m = md5_ff(
                                        m,
                                        f,
                                        r,
                                        i,
                                        d[n + 4],
                                        7,
                                        -176418897
                                      )),
                                      f,
                                      r,
                                      d[n + 5],
                                      12,
                                      1200080426
                                    )),
                                    m,
                                    f,
                                    d[n + 6],
                                    17,
                                    -1473231341
                                  )),
                                  i,
                                  m,
                                  d[n + 7],
                                  22,
                                  -45705983
                                )),
                                (r = md5_ff(
                                  r,
                                  (i = md5_ff(
                                    i,
                                    (m = md5_ff(
                                      m,
                                      f,
                                      r,
                                      i,
                                      d[n + 8],
                                      7,
                                      1770035416
                                    )),
                                    f,
                                    r,
                                    d[n + 9],
                                    12,
                                    -1958414417
                                  )),
                                  m,
                                  f,
                                  d[n + 10],
                                  17,
                                  -42063
                                )),
                                i,
                                m,
                                d[n + 11],
                                22,
                                -1990404162
                              )),
                              (r = md5_ff(
                                r,
                                (i = md5_ff(
                                  i,
                                  (m = md5_ff(
                                    m,
                                    f,
                                    r,
                                    i,
                                    d[n + 12],
                                    7,
                                    1804603682
                                  )),
                                  f,
                                  r,
                                  d[n + 13],
                                  12,
                                  -40341101
                                )),
                                m,
                                f,
                                d[n + 14],
                                17,
                                -1502002290
                              )),
                              i,
                              m,
                              d[n + 15],
                              22,
                              1236535329
                            )),
                            (r = md5_gg(
                              r,
                              (i = md5_gg(
                                i,
                                (m = md5_gg(
                                  m,
                                  f,
                                  r,
                                  i,
                                  d[n + 1],
                                  5,
                                  -165796510
                                )),
                                f,
                                r,
                                d[n + 6],
                                9,
                                -1069501632
                              )),
                              m,
                              f,
                              d[n + 11],
                              14,
                              643717713
                            )),
                            i,
                            m,
                            d[n + 0],
                            20,
                            -373897302
                          )),
                          (r = md5_gg(
                            r,
                            (i = md5_gg(
                              i,
                              (m = md5_gg(m, f, r, i, d[n + 5], 5, -701558691)),
                              f,
                              r,
                              d[n + 10],
                              9,
                              38016083
                            )),
                            m,
                            f,
                            d[n + 15],
                            14,
                            -660478335
                          )),
                          i,
                          m,
                          d[n + 4],
                          20,
                          -405537848
                        )),
                        (r = md5_gg(
                          r,
                          (i = md5_gg(
                            i,
                            (m = md5_gg(m, f, r, i, d[n + 9], 5, 568446438)),
                            f,
                            r,
                            d[n + 14],
                            9,
                            -1019803690
                          )),
                          m,
                          f,
                          d[n + 3],
                          14,
                          -187363961
                        )),
                        i,
                        m,
                        d[n + 8],
                        20,
                        1163531501
                      )),
                      (r = md5_gg(
                        r,
                        (i = md5_gg(
                          i,
                          (m = md5_gg(m, f, r, i, d[n + 13], 5, -1444681467)),
                          f,
                          r,
                          d[n + 2],
                          9,
                          -51403784
                        )),
                        m,
                        f,
                        d[n + 7],
                        14,
                        1735328473
                      )),
                      i,
                      m,
                      d[n + 12],
                      20,
                      -1926607734
                    )),
                    (r = md5_hh(
                      r,
                      (i = md5_hh(
                        i,
                        (m = md5_hh(m, f, r, i, d[n + 5], 4, -378558)),
                        f,
                        r,
                        d[n + 8],
                        11,
                        -2022574463
                      )),
                      m,
                      f,
                      d[n + 11],
                      16,
                      1839030562
                    )),
                    i,
                    m,
                    d[n + 14],
                    23,
                    -35309556
                  )),
                  (r = md5_hh(
                    r,
                    (i = md5_hh(
                      i,
                      (m = md5_hh(m, f, r, i, d[n + 1], 4, -1530992060)),
                      f,
                      r,
                      d[n + 4],
                      11,
                      1272893353
                    )),
                    m,
                    f,
                    d[n + 7],
                    16,
                    -155497632
                  )),
                  i,
                  m,
                  d[n + 10],
                  23,
                  -1094730640
                )),
                (r = md5_hh(
                  r,
                  (i = md5_hh(
                    i,
                    (m = md5_hh(m, f, r, i, d[n + 13], 4, 681279174)),
                    f,
                    r,
                    d[n + 0],
                    11,
                    -358537222
                  )),
                  m,
                  f,
                  d[n + 3],
                  16,
                  -722521979
                )),
                i,
                m,
                d[n + 6],
                23,
                76029189
              )),
              (r = md5_hh(
                r,
                (i = md5_hh(
                  i,
                  (m = md5_hh(m, f, r, i, d[n + 9], 4, -640364487)),
                  f,
                  r,
                  d[n + 12],
                  11,
                  -421815835
                )),
                m,
                f,
                d[n + 15],
                16,
                530742520
              )),
              i,
              m,
              d[n + 2],
              23,
              -995338651
            )),
            (r = md5_ii(
              r,
              (i = md5_ii(
                i,
                (m = md5_ii(m, f, r, i, d[n + 0], 6, -198630844)),
                f,
                r,
                d[n + 7],
                10,
                1126891415
              )),
              m,
              f,
              d[n + 14],
              15,
              -1416354905
            )),
            i,
            m,
            d[n + 5],
            21,
            -57434055
          )),
          (r = md5_ii(
            r,
            (i = md5_ii(
              i,
              (m = md5_ii(m, f, r, i, d[n + 12], 6, 1700485571)),
              f,
              r,
              d[n + 3],
              10,
              -1894986606
            )),
            m,
            f,
            d[n + 10],
            15,
            -1051523
          )),
          i,
          m,
          d[n + 1],
          21,
          -2054922799
        )),
        (r = md5_ii(
          r,
          (i = md5_ii(
            i,
            (m = md5_ii(m, f, r, i, d[n + 8], 6, 1873313359)),
            f,
            r,
            d[n + 15],
            10,
            -30611744
          )),
          m,
          f,
          d[n + 6],
          15,
          -1560198380
        )),
        i,
        m,
        d[n + 13],
        21,
        1309151649
      )),
      (r = md5_ii(
        r,
        (i = md5_ii(
          i,
          (m = md5_ii(m, f, r, i, d[n + 4], 6, -145523070)),
          f,
          r,
          d[n + 11],
          10,
          -1120210379
        )),
        m,
        f,
        d[n + 2],
        15,
        718787259
      )),
      i,
      m,
      d[n + 9],
      21,
      -343485551
    )),
      (m = safe_add(m, h)),
      (f = safe_add(f, t)),
      (r = safe_add(r, g)),
      (i = safe_add(i, e));
  }
  return Array(m, f, r, i);
}
function md5_cmn(d, _, m, f, r, i) {
  return safe_add(bit_rol(safe_add(safe_add(_, d), safe_add(f, i)), r), m);
}
function md5_ff(d, _, m, f, r, i, n) {
  return md5_cmn((_ & m) | (~_ & f), d, _, r, i, n);
}
function md5_gg(d, _, m, f, r, i, n) {
  return md5_cmn((_ & f) | (m & ~f), d, _, r, i, n);
}
function md5_hh(d, _, m, f, r, i, n) {
  return md5_cmn(_ ^ m ^ f, d, _, r, i, n);
}
function md5_ii(d, _, m, f, r, i, n) {
  return md5_cmn(m ^ (_ | ~f), d, _, r, i, n);
}
function safe_add(d, _) {
  var m = (65535 & d) + (65535 & _);
  return (((d >> 16) + (_ >> 16) + (m >> 16)) << 16) | (65535 & m);
}
function bit_rol(d, _) {
  return (d << _) | (d >>> (32 - _));
}

export function sleep(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

var trackerCount = {};
var getTracker = function (title, opts) {
  opts = opts || {};

  if (trackerCount[title] == null) trackerCount[title] = 0;

  var tracker = {
    start: trackerCount[title],
    title: title,
    conditions: opts.conditions || []
  };

  tracker.step = function () {
    trackerCount[title]++;
    return getTracker(title);
  };

  return tracker;
};
export { getTracker };

var isDone = function (tracker) {
  if (tracker == null) return false;
  return (
    trackerCount[tracker.title] > tracker.start ||
    tracker.conditions.some(function (condition) {
      return isDone(condition);
    })
  );
};
export { isDone };

var for2 = function (opts, func, callback) {
  opts = opts || {};

  var done = callback || opts.done || function () {};
  var finished = false;
  var tracker = opts.track;

  if (opts.list == null) return callback;

  if (opts.list.length != null) {
    // Array
    var values = opts.list;
    var keys = range(0, opts.list.length);
  } else {
    // Obj
    var values = _.values(opts.list);
    var keys = Object.keys(opts.list);
  }

  var blockThreshold = 80; // ms

  var i = 0;
  var checkNext = function () {
    var start = typeof performance != "undefined" ? performance.now() : 0;

    var stop = false;
    var wait = function () {
      if (opts.wait != null) opts.wait(); // waiting the upper layers as well
      stop = true;
    };
    while (i < values.length) {
      if (isDone(tracker)) return;

      // If time so far is over threshold and hasn't waited yet, start waiting
      if (!opts.block && !stop) {
        var now = typeof performance != "undefined" ? performance.now() : 0;
        if (now - start > blockThreshold) {
          // console.log('took ', (now - start), 'ms');
          if (now - start > 500) {
            // Maybe tell next for loop when this last blocked so it can use that as a baseline for when to stop blocking
            // debugger
          }
          start = typeof performance != "undefined" ? performance.now() : 0;

          wait(); // Don't introduce asynchrony without notifying super methods
          setTimeout(checkNext, 0);
          break;
        }
      }

      func(
        values[i],
        function (opts) {
          opts = opts || {};

          if (opts.break) {
            stop = true; // easy way to break a loop
            return done();
          }
          if (!stop) return; // Only works when waited first

          if (!opts.sync && !opts.block) {
            // Default to async to prevent stack traces overflowing
            setTimeout(checkNext, 0);
          } else {
            checkNext();
          }
        },
        keys[i],
        wait
      );
      i++;

      if (!opts.block && !stop) {
        var now = typeof performance != "undefined" ? performance.now() : 0;
        if (now - start > blockThreshold) {
          // console.log('took ', (now - start), 'ms');
          if (now - start > 500) {
            // Just checking
            // debugger
          }
        }
      }

      if (stop) break;
    }

    if (i == values.length && !stop && !isDone(tracker)) {
      if (finished) debugger; // Shouldn't happen more than once
      done(); // Actually done
      finished = true;
    }
  };
  checkNext();
};
export { for2 };

var after = function (before, after, opts) {
  opts = opts || {};
  var tracker = opts.track;
  before(function () {
    if (isDone(tracker)) {
      console.log(`isDone(${tracker}) true`);
      return;
    }
    after.apply(null, arguments);
  });
};
export { after };

var capFirst = function (text) {
  return text == null || text.length == 0
    ? ""
    : text[0].toUpperCase() + text.slice(1);
};
export { capFirst };

var capFirstOnly = function (text) {
  return text == null || text.length == 0
    ? ""
    : text[0].toUpperCase() + text.slice(1).toLowerCase();
};
export { capFirstOnly };

var upperFirstChar = function (text) {
  return text.slice(0, 1).toUpperCase() + text.slice(1, text.length);
};
export { upperFirstChar };

/**
 *
 * @param { {} } obj
 * @param { string } key
 * @param { {} } default_val
 * @returns { {} }
 */
var getKey = function (obj, key, default_val) {
  if (obj[key] == null) obj[key] = default_val;
  return obj[key];
};
export { getKey };

var useN = function (n) {
  for (var i = 1; i < arguments.length - 1; i++) arguments[i](n);
  return arguments[i](n); // Return the result of the last one.
};
export { useN };

var removeSpecialChar = function (text) {
  return text
    .replaceAll(" ", " ")
    .replaceAll(
      /[^A-zÀ-ῼ 0-9\n\t \.,\?“”’"'"!@#\$฿₹₾€¥£%\^&\*\(\)–—\-_=\+;:<>\/\\\|\}\{\[\]`~•†‡]*/g,
      ""
    );
  //À-ÿ
  //.replace(/\p{C}/g, "");
};
export { removeSpecialChar };

var validEmail = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export { validEmail };

export const makeId = (length) => {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
